import gsap from 'gsap'
const { getCurrentTheme } = useTheme()
import { getKerning } from '~/utils/getKerning'
import { CustomEase } from 'gsap/all'
gsap.registerPlugin(CustomEase)

const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))
let overlay,
  loader = null
const overlayTl = gsap.timeline({ paused: true })
const state = {
  duration: 1.2,
  wordDuration: 2,
  wordChangeDuration: 0,
  delayBetweenWords: 0.2,
  ease: CustomEase.create('custom', '0.5, 0, 0, 1'),
}

function onBeforeEnter(el, style) {
  const route = useRoute()
  if (route.query.token) return

  style.value = { opacity: 0 }
}

function onEnter(el, style) {
  const route = useRoute()
  if (route.query.token) return

  return new Promise(async resolve => {
    scrollLock(true)
    await delay(0)
    document.querySelector('.overlay-wipe')?.remove()
    await delay(600)
    style.value = { opacity: 1 }

    const currentTheme = getCurrentTheme()

    overlay = document.createElement('div')
    overlay.classList.add('overlay-wipe')

    loader = document.querySelector('.loader')
    gsap.set(overlay, {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: 19000,
      backgroundColor: currentTheme.background.rgbString,
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
    })
    gsap.set(loader, {
      zIndex: 20000,
      color: currentTheme.text.rgbString,
    })
    document.body.append(overlay)
    const lines = gsap.utils.toArray('.lines')

    overlayTl
      .to(overlay, {
        duration: state.duration / 1.3,
        ease: state.ease,
        y: '100vh',
      })
      .to(
        loader,
        {
          duration: state.duration / 1.9,
          ease: 'power2.inOut',
          y: '100vh',
        },
        '<'
      )
      .to(
        loader,
        {
          duration: 0.4,
          ease: 'power2.inOut',
          opacity: 0,
          onComplete: () => {
            caseLoad()
          },
        },
        '<'
      )

    const mediaContainer = el.querySelector('.media-container')
    const { top } = mediaContainer?.getBoundingClientRect() || {}

    const background = el.querySelector('.background-wrapper')
    const foreground = el.querySelector('.foreground-wrapper')
    const topbar = document.querySelector('.top-bar')
    const title = el.querySelector('h1')

    const newHeight = Math.min(window.innerHeight, (window.innerWidth * 4) / 3)
    const newHeightOffset = window.innerHeight - newHeight

    if (background)
      gsap.set(background, {
        y: -top + newHeightOffset / 2,
        height: window.innerHeight,
      })
    if (foreground) gsap.set(foreground, { y: -top + newHeightOffset / 2 })

    const { transitionProperty } = getComputedStyle(document.body)
    document.body.style.transitionProperty = transitionProperty + ', opacity'
    document.body.style.opacity = 1

    let currentItemIndex = 0
    function animateItems() {
      const currentItem = lines[currentItemIndex]
      const prevItemIndex =
        currentItemIndex === 0 ? lines.length - 1 : currentItemIndex - 1
      const prevItem = lines[prevItemIndex]

      if (!prevItem) return

      gsap.to(prevItem, {
        opacity: 0,
        duration: state.wordChangeDuration,
      })

      gsap.to(currentItem, {
        opacity: 1,
        duration: state.wordChangeDuration,
        onComplete: () => {
          if (currentItemIndex === lines.length - 1) {
            setTimeout(() => {
              overlayTl.play()
            }, 300)
          }
        },
      })

      currentItemIndex++

      if (currentItemIndex < lines.length) {
        setTimeout(
          animateItems,
          (state.wordChangeDuration + state.delayBetweenWords) * 1000
        )
      } else {
        if (currentItem) currentItem.style.opacity = 1
      }
    }

    animateItems()

    async function caseLoad() {
      await delay(400)

      if (background) {
        gsap.to(background, {
          duration: state.duration,
          ease: state.ease,
          y: 0,
          height: newHeight,
          onComplete() {
            setTimeout(() => {
              document.body.style.removeProperty('opacity')
              document.body.style.removeProperty('transition-property')
              gsap.set(topbar, { clearProps: 'all' })
              resolve()
            }, 250)
          },
        })
      }

      if (foreground) {
        gsap.to(foreground, {
          duration: state.duration,
          ease: state.ease,
          delay: state.duration * 0.04,
          y: 0,
        })
      }

      if (title) {
        const initialTitle = title.textContent
        const titleKerning = getKerning(initialTitle.toUpperCase())

        title.textContent = ''

        titleKerning.forEach(item => {
          const span = document.createElement('span')
          span.style.display = 'inline-block'
          span.style.marginLeft = item.marginLeft + 'em'
          span.textContent = item.letter
          title.append(span)
          gsap.from(span, {
            duration: state.duration,
            ease: state.ease,
            delay: Math.random() * 0.25,
            opacity: 0,
            y: '-50%',
          })
        })
      }

      gsap.from(topbar, {
        duration: state.duration,
        delay: state.duration * 0.04,
        ease: state.ease,
        y: '-5vh',
      })
    }
  })
}

function onAfterEnter(el, style) {
  const route = useRoute()
  if (route.query.token) return
  
  style.value = null
  loader.remove()
  overlay.remove()
  scrollLock(false)
}

export default {
  onBeforeEnter,
  onEnter,
  onAfterEnter,
}
