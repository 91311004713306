export default (
  el,
  { pageLoads = {}, errorPageLoad = {}, defaultPageLoad = {} } = {},
  loaderData
) => {
  const router = useRouter()
  const error = useError()
  const errorLoad = ref(false)
  const getPageLoad = route => {
    watch(
      error,
      () => {
        if (error.value) errorLoad.value = true
      },
      { immediate: true }
    )
    return errorLoad.value
      ? pageLoads['error']
      : pageLoads[route] || defaultPageLoad
  }

  const currentRoute = computed(
    () =>
      router
        .getRoutes()
        .find(({ name }) => name === router.currentRoute.value.name)?.name
  )
  const style = ref(null)
  const currentPageLoad = getPageLoad(currentRoute.value)
  currentPageLoad.onBeforeEnter(el.value, style, loaderData)

  // onServerPrefetch(() => {
  //   // init setup, before the dom is painted
  // })

  onMounted(async () => {
    if (!process.client) return
    const cookieBanner = document.querySelector('#coiOverlay')
    // the transition
    await currentPageLoad.onEnter(el.value, style, loaderData)
    if (cookieBanner) {
      cookieBanner.style.opacity = 1
    }

    // after transition, individual clean up
    currentPageLoad.onAfterEnter(el.value, style, loaderData)
    // after everything, global clean up
    style.value = null
  })

  return { style }
}
