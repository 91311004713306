import gsap from 'gsap'
import { getKerning } from '~/utils/getKerning'
import { CustomEase } from 'gsap/all'
gsap.registerPlugin(CustomEase)

const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))

const state = {
    duration: 1.2,
    ease: CustomEase.create("custom", "0.5, 0, 0, 1"),
}

function onBeforeEnter(el, style) {
    style.value = { opacity: 0 }
}

function onEnter(el, style) {
    return new Promise(async resolve => {
        document.body.style.opacity = 0
        style.value = { opacity: 1 }

        await delay(0)

        const mediaContainer = el.querySelector('.media-container')
        const { top } = mediaContainer?.getBoundingClientRect() || {}

        const background = el.querySelector('.background-wrapper')
        const foreground = el.querySelector('.foreground-wrapper')
        const topbar = document.querySelector('.top-bar')
        const title = el.querySelector('h1')

        const newHeight = Math.min(window.innerHeight, (window.innerWidth * 4) / 3)
        const newHeightOffset = window.innerHeight - newHeight

        if (background)
            gsap.set(background, {
                y: -top + newHeightOffset / 2,
                height: window.innerHeight,
            })
        if (foreground) gsap.set(foreground, { y: -top + newHeightOffset / 2 })

        const { transitionProperty } = getComputedStyle(document.body)
        document.body.style.transitionProperty = transitionProperty + ', opacity'
        document.body.style.opacity = 1

        await delay(400)

        if (background) {
            gsap.to(background, {
                duration: state.duration,
                ease: state.ease,
                y: 0,
                height: newHeight,
                onComplete() {
                    setTimeout(() => {
                        document.body.style.removeProperty('opacity')
                        document.body.style.removeProperty('transition-property')
                        gsap.set(topbar, { clearProps: 'all' })
                        resolve()
                    }, 250)
                },
            })
        }

        if (foreground) {
            gsap.to(foreground, {
                duration: state.duration,
                ease: state.ease,
                delay: state.duration * 0.04,
                y: 0,
            })
        }

        if (title) {
            const initialTitle = title.textContent
            const titleKerning = getKerning(initialTitle.toUpperCase())

            title.textContent = ''

            titleKerning.forEach(item => {
                const span = document.createElement('span')
                span.style.display = 'inline-block'
                span.style.marginLeft = item.marginLeft + 'em'
                span.textContent = item.letter
                title.append(span)
                gsap.from(span, {
                    duration: state.duration,
                    ease: state.ease,
                    delay: Math.random() * 0.25,
                    opacity: 0,
                    y: '-50%',
                })
            })
        }

        gsap.from(topbar, {
            duration: state.duration,
            delay: state.duration * 0.04,
            ease: state.ease,
            y: '-5vh',
        })
    })
}

function onAfterEnter(el, style) {
    style.value = null
}

export default {
    onBeforeEnter,
    onEnter,
    onAfterEnter,
}