<template>
  <div class="dev-grid">
    <div v-for="idx in 12" :key="idx">{{ idx }}</div>
  </div>
</template>

<style lang="postcss">
.dev-grid {
  --helpercolor: 210 100% 56%;

  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000000;

  display: grid;
  grid-template-columns: repeat(var(--grid-cols), cols(1));
  padding: 0 var(--grid-margin);
  gap: var(--grid-gap);

  pointer-events: none;
  user-select: none;

  div {
    color: hsla(var(--helpercolor) / 0.4);
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: hsla(var(--helpercolor) / 0.05);
    border-left: 1px solid hsla(var(--helpercolor) / 0.4);
    border-right: 1px solid hsla(var(--helpercolor) / 0.4);

    &:nth-of-type(n + 7) {
      display: none;
    }

    @media (--md) {
      &:nth-of-type(n + 7) {
        display: flex;
      }
    }
  }
}
</style>
