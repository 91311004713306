import gsap from 'gsap'
const { getCurrentTheme } = useTheme()
import { CustomEase } from 'gsap/all'
gsap.registerPlugin(CustomEase)

const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))
const overlayTl = gsap.timeline({ paused: true })
const pageTl = gsap.timeline({ paused: true })
const state = {
  duration: 2,
  wordChangeDuration: 0,
  delayBetweenWords: 0.2,
  ease: CustomEase.create('custom', '0.25, 0, 0, 1'),
}

let overlay,
  loader = null

function onBeforeEnter(el, style, loaderData) {
  style.value = { opacity: 0 }
}

function onEnter(el, style, loaderData) {
  return new Promise(async resolve => {
    scrollLock(true)
    await delay(0)
    // if there for some reason was an overlay on the page
    document.querySelector('.overlay-wipe')?.remove()

    await delay(600) // body background fade duration

    style.value = { opacity: 1 }

    const currentTheme = getCurrentTheme()

    overlay = document.createElement('div')
    overlay.classList.add('overlay-wipe')

    loader = document.querySelector('.loader')
    gsap.set(overlay, {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: 19000,
      backgroundColor: currentTheme.background.rgbString,
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
    })
    gsap.set(loader, {
      zIndex: 20000,
      color: currentTheme.text.rgbString,
    })

    const page = document.querySelector('.error-page')
    const layout = document.querySelector('.error-page-layout')
    layout?.append(overlay)

    const lines = gsap.utils.toArray('.lines')

    let currentItemIndex = 0
    overlayTl
      .to(overlay, {
        duration: state.duration / 1.3,
        ease: state.ease,
        y: '100vh',
      })
      .to(
        loader,
        {
          duration: state.duration / 1.9,
          ease: 'power2.inOut',
          y: '100vh',
        },
        '<'
      )
      .to(
        loader,
        {
          duration: 0.6,
          ease: 'power2.inOut',
          opacity: 0,
        },
        '<'
      )

    pageTl.from(page, {
      duration: state.duration,
      ease: state.ease,
      y: '-10vh',
      onComplete: () => {
        gsap.set(page, { clearProps: 'all' })
        resolve()
      },
    })

    function animateItems() {
      const currentItem = lines[currentItemIndex]
      const prevItemIndex =
        currentItemIndex === 0 ? lines.length - 1 : currentItemIndex - 1
      const prevItem = lines[prevItemIndex]

      if (!prevItem) return

      gsap.to(prevItem, {
        opacity: 0,
        ease: state.ease,
        duration: state.wordChangeDuration,
      })

      gsap.to(currentItem, {
        opacity: 1,
        ease: state.ease,
        duration: state.wordChangeDuration,
        onComplete: () => {
          if (currentItemIndex === lines.length - 1) {
            setTimeout(() => {
              overlayTl.play()
              if (page) {
                pageTl.play()
              }
            }, 300)
          }
        },
      })

      currentItemIndex++

      if (currentItemIndex < lines.length) {
        setTimeout(
          animateItems,
          (state.wordChangeDuration + state.delayBetweenWords) * 1000
        )
      } else {
        if (currentItem) {
          currentItem.style.opacity = 1
        }
      }
    }

    animateItems()
  })
}

function onAfterEnter(el, style, loaderData) {
  style.value = null
  loader.remove()
  overlay.remove()
  scrollLock(false)
}

export default {
  onBeforeEnter,
  onEnter,
  onAfterEnter,
}
