import gsap from 'gsap'
const { getCurrentTheme } = useTheme()
import { CustomEase } from 'gsap/all'
gsap.registerPlugin(CustomEase)

const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))

const state = {
    duration: 1.6,
    ease: CustomEase.create("custom", "0.25, 0, 0, 1"),
}

let overlay = null

function onBeforeEnter(el, style) {
    style.value = { opacity: 0 }
}

function onEnter(el, style) {
    return new Promise(async resolve => {
        await delay(0)

        // if there for some reason was an overlay on the page
        document.querySelector('.overlay-wipe')?.remove()

        await delay(600) // body background fade duration

        style.value = { opacity: 1 }

        const currentTheme = getCurrentTheme()

        overlay = document.createElement('div')
        overlay.classList.add('overlay-wipe')

        gsap.set(overlay, {
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 19000,
            y: '0vh',
            backgroundColor: currentTheme.background.rgbString,
            boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
        })

        document.body.append(overlay)

        const page = document.querySelector('.page')
        const topBar = document.querySelector('.top-bar')

        gsap.from([page ? page : null, topBar ? topBar : null], {
            duration: state.duration,
            ease: state.ease,
            y: '-10vh',
            onComplete: () => {
                gsap.set([page ? page : null, topBar ? topBar : null], { clearProps: 'all' })
                resolve()
            },
        })

        gsap.to(overlay, {
            duration: state.duration,
            ease: state.ease,
            y: '100vh',
        })
    })
}

function onAfterEnter(el, style) {
    style.value = null
    overlay.remove()
}

export default {
    onBeforeEnter,
    onEnter,
    onAfterEnter,
}